<template>
  <div class="Page">
    <h1>This is an page</h1>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  created (opt) {

  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.Page {
  margin-top: 0px;
}
</style>
